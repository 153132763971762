@tailwind base;
@tailwind components;
@tailwind utilities;

.tone-1 {
  filter: hue-rotate(30deg);
}

.tone-2 {
  filter: hue-rotate(60deg);
}

.tone-3 {
  filter: hue-rotate(90deg);
}

.tone-4 {
  filter: hue-rotate(120deg);
}

.tone-5 {
  filter: hue-rotate(150deg);
}

.tone-6 {
  filter: hue-rotate(180deg);
}

.tone-7 {
  filter: hue-rotate(210deg);
}

.tone-8 {
  filter: hue-rotate(240deg);
}

.tone-9 {
  filter: hue-rotate(270deg);
}

.tone-1- {
  filter: hue-rotate(30deg);
}
